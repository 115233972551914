import React, { useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'

import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'

import ArrowDownIcon from '@/assets/images/arrow_down_icon.svg'

import { request } from '../../api/apiCore'
import { useAuth } from '../../auth/Auth'
import { isAdminRoute, removeItemFromLocalStorage } from '../../helpers/helpers'
import { useIdGobPeAcf } from '../../hooks/useIdGobPe'

const getFirstLetter = (name = '') => (name.match(/[a-z]/i).length > 0 ? name.match(/[a-z]/i)[0].toUpperCase() : '')

export const UserMenu = () => {
  const { user, dispatch, admin, adminDispatch } = useAuth()
  const { logout, profile } = useIdGobPeAcf()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const currentUser = user.logged && !isAdminRoute ? user : admin
  const username =
    !isAdminRoute && currentUser.name?.split(' ').length > 0 ? currentUser.name.split(' ')[0] : currentUser.name

  const handleToggle = () => setOpen(prevOpen => !prevOpen)

  const handleLogout = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return

    if (user.logged && !isAdminRoute) {
      handleToggle()

      if (user.type === 'idGobPe') {
        removeItemFromLocalStorage(['userData'])
        logout()
      } else {
        dispatch({ type: 'Logout' })
      }

      return
    }

    if (admin.logged) {
      handleToggle()
      request({ url: '/admin/sessions', method: 'DELETE' }).then(adminDispatch({ type: 'Logout' }))
      return
    }
  }

  const handleSelectedItem = () => {
    handleToggle()
    profile()
  }

  if (!isAdminRoute && !user.logged) return null
  if (isAdminRoute && !admin.logged) return null
  if (!user.logged && !admin.logged) return null

  return (
    <>
      <button
        className="flex text-sm items-center ml-auto"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <div className="bg-white p-2 text-red-700 font-bold rounded">
          {getFirstLetter(currentUser.name)}
          {currentUser.father_last_name ? getFirstLetter(currentUser.father_last_name) : ''}
        </div>
        <div className="pl-4 text-white font-bold hidden md:block">
          {`${username} ${currentUser.father_last_name ? currentUser.father_last_name : ''}`}
        </div>
        <ReactSVG src={ArrowDownIcon} className="ml-2 md:ml-4 text-white" />
      </button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
        className="z-10"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <MenuList autoFocusItem={open} id="menu-list-grow">
                {!isAdminRoute && <MenuItem onClick={() => handleSelectedItem('/perfil')}>Mi perfil</MenuItem>}
                <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
