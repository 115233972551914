import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import { useCustomField } from '@/hooks/useCustomField'

import { fieldWidthSizes, marginSizes } from '../../utils/sizes'
import Error from './Error'

const TextField = ({
  name,
  label,
  className,
  rootClassName,
  validate,
  hint,
  size = 'xl',
  disabled = false,
  element: Element = 'input',
  orientation = 'vertical',
  margin = 'normal',
  leftDecorator,
  rows,
  labelClassName = 'mb-2',
  errorClassName,
  rightDecorator: RightDecorator,
  maxLength,
  background = 'bg-white',
  showLengthDescription = false,
  ...props
}) => {
  const { input, meta, gotError } = useCustomField(name, {
    parse: a => a,
    validate,
    ...props
  })

  const isHorizontal = orientation === 'horizontal'
  const hasRigthDecoratorAndNoErrors = RightDecorator && !meta.error

  return (
    <div className={clsx(marginSizes[margin], 'relative', rootClassName, { 'flex items-baseline': isHorizontal })}>
      {label && (
        <label
          className={clsx('block font-bold', { 'mr-4': isHorizontal }, labelClassName)}
          id={`${name}-label`}
          htmlFor={`${name}-input`}
          aria-describedby={`${name}-hint`}
        >
          {label}
        </label>
      )}
      {hint && (
        <p id={`${name}-hint`} className="break-words mb-2">
          {hint}
        </p>
      )}
      <div className={clsx({ 'relative w-full': isHorizontal })}>
        {leftDecorator}
        <div className="flex flex-wrap">
          <Element
            id={`${name}-input`}
            className={clsx(
              'border-2 border-gray-900 py-2 px-4 rounded bg-white focus:outline-none h-12',
              background,
              hasRigthDecoratorAndNoErrors ? 'w-4/5' : 'w-full',
              { 'bg-blue-300 text-gray-90 border-gray-100': disabled },
              fieldWidthSizes[size],
              className,
              gotError ? 'focus-within:border-red-500 border-red-500' : 'focus-within:border-yellow-500'
            )}
            aria-label={props['aria-label']}
            aria-describedby={`${name}-hint`}
            disabled={disabled}
            rows={rows}
            placeholder={props['placeholder']}
            maxLength={maxLength}
            {...input}
            {...props}
          />
          {hasRigthDecoratorAndNoErrors && <RightDecorator inputValue={input.value} />}
        </div>
        <Error name={name} className={clsx('pt-2', errorClassName)} />
        {showLengthDescription && (
          <p className={clsx('text-end text-sm text-gray-830 mt-1')}>
            {`${input.value.length}/${maxLength} caracteres`}
          </p>
        )}
      </div>
    </div>
  )
}

export default TextField

TextField.propTypes = {
  element: PropTypes.string,
  orientation: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  validate: PropTypes.func,
  hint: PropTypes.string,
  classes: PropTypes.object,
  type: PropTypes.string,
  name: PropTypes.string,
  margin: PropTypes.string,
  label: PropTypes.string,
  rootClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  'aria-label': PropTypes.string,
  placeholder: PropTypes.string,
  leftDecorator: PropTypes.element,
  rightDecorator: PropTypes.element,
  maxLength: PropTypes.number,
  background: PropTypes.string,
  rows: PropTypes.number,
  showLengthDescription: PropTypes.bool
}
