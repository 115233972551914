import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const Border = ({ className }) => <div className={clsx(className, 'border-b border-blue-200')}></div>

const TextBetweenLines = ({ content, borderClassName }) => {
  return (
    <div className="py-5 md:py-7 flex justify-between text-center">
      <Border className={borderClassName} />
      <span>{content}</span>
      <Border className={borderClassName} />
    </div>
  )
}

TextBetweenLines.propTypes = {
  content: PropTypes.string,
  borderClassName: PropTypes.string
}

Border.propTypes = {
  className: PropTypes.string
}

export default TextBetweenLines
