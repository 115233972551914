import React from 'react'

import { Breadcrumbs, Link, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import ChevronLeftIcon from '../../assets/images/chevron_left_icon.svg'
import ChevronRightIcon from '../../assets/images/chevron_right_icon.svg'
import { allRoutes } from '../../configs/routes'
import { Container } from '../Container'

const linkStyles = makeStyles(() => ({
  root: { color: '#0056AC', fontWeight: 700, fontSize: 14, display: 'flex', alignItems: 'center' }
}))

const Breadcrumb = ({ routesKey, data }) => {
  const breadcrumbContent = Array.isArray(data) ? [...data] : [data]
  const breadcrumbRoutes = data !== null ? [...allRoutes[routesKey], ...breadcrumbContent] : allRoutes[routesKey]
  const { route, label } = breadcrumbRoutes.find(route => route.showInMobile)
  const linkClasses = linkStyles()

  return (
    <Container className="px-5 md:px-20 py-2.5">
      <div className="hidden md:block">
        <Breadcrumbs separator={<img src={ChevronRightIcon} className="mx-2" />} aria-label="breadcrumb">
          {breadcrumbRoutes.map((item, index) => {
            if (item.route) {
              return (
                <Link key={index} href={item.route} classes={linkClasses}>
                  {item.label}
                </Link>
              )
            } else {
              return (
                <span key={index} className="font-bold text-sm text-gray-850">
                  {item.label}
                </span>
              )
            }
          })}
        </Breadcrumbs>
      </div>
      <div className="block md:hidden">
        <Link href={route} classes={linkClasses}>
          <img src={ChevronLeftIcon} className="mr-2.5" />
          <span>{label}</span>
        </Link>
      </div>
    </Container>
  )
}

Breadcrumb.propTypes = {
  routesKey: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export default Breadcrumb
