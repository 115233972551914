import { useLocation } from 'react-router-dom'

import { ContentState, EditorState, convertToRaw } from 'draft-js'
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor'
import draftToHtml from 'draftjs-to-html'
import queryString from 'query-string'

import { apiGetPdfRequest } from '../api/apiCore'
import { APP_ENV } from '../constants/globals'

export const dataToOptions = (data, [value, label]) =>
  data.data?.map(option => ({
    value: option[value],
    label: option[label]
  }))

export const resultsToOptions = (results, [value, label]) =>
  results?.map(option => ({
    value: option[value],
    label: option[label]
  }))

export const pluralize = (count, singular, plural, locale = 'es-ES') => {
  const pluralRules = new Intl.PluralRules(locale)
  const numbering = pluralRules.select(count)

  switch (numbering) {
    case 'one':
      return `${count} ${singular}`
    case 'other':
      return `${count} ${plural}`
    default:
      throw new Error(`Unknown: ${numbering}`)
  }
}

export const truncateText = (text, maxLength) => (text.length > maxLength ? `${text.slice(0, maxLength)}...` : text)

export const findElement = (array = [], key, value) => array.find(item => item[key] === value)

export const filterObject = (object = {}, keys = []) => Object.fromEntries(keys.map(key => [key, object[key]]))

export const hideText = (text, chars, textType) => {
  switch (textType) {
    case 'email':
      return text?.replace(
        /[a-z0-9\-_.]+@/gi,
        c =>
          c.substr(0, chars) +
          c
            .split('')
            .slice(chars, -1)
            .map(() => '*')
            .join('') +
          '@'
      )
    case 'cellphone':
      return text?.replace(
        /[a-z0-9\-_.]+/gi,
        c =>
          c.substr(0, chars) +
          c
            .split('')
            .slice(chars, -1)
            .map(() => '*')
            .join('') +
          c.substr(c.length - chars, chars)
      )
  }
}

export const isNaturalPersonsRUC = rucNumber => rucNumber.substring(0, 2) === '10'

export const convertToDni = rucNumber => rucNumber.substring(2, rucNumber.length - 1)

export const parseHtml2EditorState = html => {
  const processedHTML = DraftPasteProcessor.processHTML(html)
  const contentState = ContentState.createFromBlockArray(processedHTML)
  return EditorState.createWithContent(contentState)
}

export const parseEditorState2Html = state => {
  const content = state.getCurrentContent()
  const raw = convertToRaw(content)
  return draftToHtml(raw)
}

export const convertDataToBreadcrumbRoute = (label, route = null, showInMobile = false) => ({
  route,
  label,
  showInMobile
})

export const setItemToLocalStorage = (key, value) => localStorage.setItem(key, value)

export const getItemFromLocalStorage = key => localStorage.getItem(key)

export const getJsonItemFromLocalStorage = key => JSON.parse(getItemFromLocalStorage(key))

export const removeItemFromLocalStorage = keys => keys.map(key => localStorage.removeItem(key))

export const documentSendSectionProps = {
  notification: {
    attrName: 'file_infos_attributes',
    label: 'Documentos de sustento a enviar',
    hint: 'Documento principal',
    hasInitialComponent: true,
    btnAddText: 'Agregar documento adicional'
  },
  communication: {
    attrName: 'file_infos_attributes',
    label: 'Archivos adjuntos',
    hasInitialComponent: false,
    btnAddText: 'Añadir adjunto'
  },
  document: {
    attrName: 'additional_links_attributes',
    label: 'Información adicional (opcional)',
    hint: 'Estos enlaces aparecerán en la vista de detalle del documento',
    btnAddText: 'Añadir enlace'
  }
}

export const generateDocument = (response, defaultFilename) => {
  const filename =
    response.headers['content-disposition'].split('filename=')[1].split(';')[0].replace(/['"]+/g, '') || defaultFilename
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename) //or any other extension
  document.body.appendChild(link)
  link.click()
}

export const documentDownload = async (url, defaultFilename = 'ConstanciaEnvio.pdf') => {
  const response = await apiGetPdfRequest(url)
  generateDocument(response, defaultFilename)
}

export const toCapitalizeCase = s => s && s[0].toUpperCase() + s.slice(1)

export const urlSearchParamsData = () => {
  const { search } = useLocation()
  return queryString.parse(search)
}

export const formatPhoneNumber = phoneNumber =>
  `+51 ${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6)}`

export const formatLabelsObject = (labels, object) =>
  Object.entries(labels).flatMap(([key, value]) => (object[key] ? { name: value, content: object[key] } : []))

export const generateTimeSlots = (startHour, endHour, minuteInterval) => {
  const timeSlots = []
  let currentTime = new Date()

  currentTime.setHours(startHour, 0, 0, 0)

  while (currentTime.getHours() < endHour || (currentTime.getHours() === endHour && currentTime.getMinutes() === 0)) {
    const formattedHour = currentTime.getHours()
    const formattedMinute = String(currentTime.getMinutes()).padStart(2, '0')
    const period = formattedHour < 12 ? 'a.m.' : 'p.m.'
    const formattedTime = `${formattedHour}:${formattedMinute} ${period}`

    timeSlots.push(formattedTime)
    currentTime.setMinutes(currentTime.getMinutes() + minuteInterval)
  }

  return timeSlots
}

export const addHoursToTime = (timeString, hoursToAdd) => {
  const date = new Date()
  date.setHours(0, 0, 0, 0)

  const [, hour, minutes, period] = /(\d+):(\d+)\s(.+)/.exec(timeString)
  const numericHour = parseInt(hour) + (period === 'p.m.' ? 12 : 0)
  const numericMinutes = parseInt(minutes)

  date.setHours(numericHour, numericMinutes)
  date.setHours(date.getHours() + hoursToAdd)

  const formattedTime = date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })
  return formattedTime
}

export const isMailBoxEnv = APP_ENV === 'casilla'

export const appName = isMailBoxEnv ? 'Casilla Única Electrónica' : 'Carpeta Ciudadana'

export const isAdminRoute = location.pathname.search('/admin') === 0

export const getAxiosErrorMessages = error => {
  const errorMessages = {}
  const errors = error.response.data
  for (const field in errors) {
    errorMessages[field] = errors[field][0]
  }
  return errorMessages
}
