import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { request, solKeyUrl } from '../../api/apiCore'
import { useAuth } from '../../auth/Auth'
import { Container } from '../../components/Container'
import PasswordRecoveryForm from '../../components/forms/PasswordRecoveryForm'
import Loader from '../../components/ui/Loader'
import PopUpWindow from '../../components/ui/PopUpWindow'
import ScrollToTop from '../../components/ui/ScrollToTop'
import PasswordRecoveryOptions from '../../components/users/PasswordRecoveryOptions'
import { getJsonItemFromLocalStorage, removeItemFromLocalStorage } from '../../helpers/helpers'
import useShowMessage from '../../hooks/useShowMessage'
import useSolKey from '../../hooks/useSolKey'

export const PasswordRecoveryPage = () => {
  const { dispatch } = useAuth()
  const solKeyResult = useSolKey()
  const [showOptions, setShowOptions] = useState({ show: false, loader: solKeyResult.isAuthenticated })
  const [userInfo, setUserInfo] = useState({})
  const savedMessage = getJsonItemFromLocalStorage('message')
  const { message, setMessage } = useShowMessage(!!savedMessage && savedMessage)
  const navigate = useNavigate()

  removeItemFromLocalStorage(['message'])

  useEffect(() => {
    userValidation()
  }, [solKeyResult])

  const userValidation = async () => {
    try {
      if (!solKeyResult.error) {
        if (solKeyResult.documentNumber) {
          try {
            request({
              url: '/authentication/verify-document-sunat.json',
              method: 'POST',
              data: { document_type_code: 'dni', document_number: solKeyResult.documentNumber }
            }).then(response => {
              if (response.token) {
                dispatch({ type: 'RecoverPasswordSunat', payload: response })
                navigate('/contrasena-usuario')
              } else {
                setShowOptions({ ...showOptions, loader: false })
                setMessage({
                  title: 'Aviso',
                  content: (
                    <span className="pt-6 pb-7.5">{`Usuario con documento ${solKeyResult.documentNumber} no se encuentra registrado`}</span>
                  ),
                  show: true,
                  redirect: '/recuperar-contrasena'
                })
              }
            })
          } catch (error) {
            setMessage({
              title: 'Aviso',
              content: (
                <span className="pt-6 pb-7.5">{`Usuario con documento ${solKeyResult.documentNumber} no se encuentra registrado`}</span>
              ),
              show: true,
              redirect: '/recuperar-contrasena'
            })
          }
        }
      } else {
        setShowOptions({ ...showOptions, loader: false })
        setMessage({
          title: 'Importante',
          content: solKeyResult.message,
          show: true,
          redirect: '/recuperar-contrasena'
        })
      }
    } catch (error) {
      setShowOptions({ ...showOptions, loader: false })
      setMessage({
        ...message,
        title: 'Error',
        content: <span className="pt-6 pb-7.5">{error.response.data.message}</span>,
        show: true
      })
    }
  }

  const handleSubmitDocument = async ({ document_number }) => {
    try {
      const document_type_code = 'dni'
      const data = { document_type_code, document_number }
      const response = await request({ url: '/authentication/verify-document.json', method: 'POST', data })

      if (response.user) {
        setShowOptions({ ...showOptions, show: true })
        setUserInfo(response.user)
      } else {
        setMessage({
          ...message,
          title: 'Aviso',
          content: (
            <span className="pt-6 pb-7.5">{`Usuario con documento ${document_number} no se encuentra registrado`}</span>
          ),
          show: true
        })
      }
    } catch (error) {
      setMessage({
        ...message,
        title: 'Error',
        content: <span className="pt-6 pb-7.5">{error.response.data.message}</span>,
        show: true
      })
    }
  }

  const handleSubmitEmail = async () => {
    const response = await request({ url: '/authentication/send-code.json', data: userInfo, method: 'POST' })

    try {
      if (response.salt) {
        dispatch({ type: 'RecoverPasswordEmail', payload: { ...response, ...userInfo } })
        navigate('/verificar-codigo')
      } else {
        setMessage({
          ...message,
          title: 'Aviso',
          content: <span className="pt-6 pb-7.5">{response.message}</span>,
          show: true
        })
      }
    } catch (error) {
      setMessage({
        ...message,
        title: 'Error',
        content: <span className="pt-6 pb-7.5">{error.response.data.message}</span>,
        show: true
      })
    }
  }

  const handleSubmitMovil = async () => {
    try {
      const data = { ...userInfo, send_type: 'sms', recoverySms: true }
      const response = await request({ url: '/authentication/send-code.json', data, method: 'POST' })

      if (response.salt) {
        dispatch({
          type: 'RecoverPasswordEmail',
          payload: { ...response, ...userInfo, send_type: 'sms', recoverySms: true }
        })
        navigate('/verificar-codigo')
      } else {
        setMessage({
          ...message,
          title: 'Aviso',
          content: <span className="pt-6 pb-7.5">{response.message}</span>,
          show: true
        })
      }
    } catch (error) {
      setMessage({
        ...message,
        title: 'Error',
        content: <span className="pt-6 pb-7.5">{error.response.data.message}</span>,
        show: true
      })
    }
  }

  const handlePide = async () => {
    dispatch({ type: 'RecoverPasswordPide', payload: { ...userInfo } })
    navigate('/recuperar-contrasena-pide')
  }

  return (
    <Container className="px-5 pt-8 md:h-screen pb-14 md:px-20 md:pt-14 md:pb-18">
      {!showOptions.show && !showOptions.loader && (
        <PasswordRecoveryForm
          handleSubmitForm={handleSubmitDocument}
          handleCancelButton={() => navigate('/')}
        ></PasswordRecoveryForm>
      )}
      {showOptions.loader && <Loader message="Espera un momento, estamos validando los datos ingresados." />}
      {message.show && (
        <PopUpWindow
          title={message.title}
          paperProps={{
            padding: 36,
            borderRadius: 0,
            maxWidth: 482,
            boxShadow: '2px 3px 23px -4px #D3E0EC'
          }}
          showCloseButton
          open={message.show}
          onClose={() => setMessage({ ...message, show: false })}
        >
          {message.content}
        </PopUpWindow>
      )}
      {showOptions.show && (
        <PasswordRecoveryOptions
          handleEmailOptionButton={handleSubmitEmail}
          handleMovilOptionButton={handleSubmitMovil}
          handleSolOptionButton={() => solKeyUrl('recuperar-contrasena')}
          handlePideOptionButton={handlePide}
        />
      )}
      <ScrollToTop />
    </Container>
  )
}
