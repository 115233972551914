import React from 'react'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Footer from '@/components/ui/Footer'

import { useAuth } from '../auth/Auth'
import { Header } from '../components/ui/Header'
import { CodeVerifyPage } from '../pages/CodeVerifyPage'
import { LoginPage } from '../pages/LoginPage'
import { LoginPage as LoginAdminPage } from '../pages/admin/LoginPage'
import { PasswordRecoveryPage } from '../pages/users/PasswordRecoveryPage'
import { PasswordRecoveryPidePage } from '../pages/users/PasswordRecoveryPidePage'
import { TermsConditionsPage } from '../pages/users/TermsConditionsPage'
import { UserPasswordPage } from '../pages/users/UserPasswordPage'
import { AdminRoutes } from './AdminRoutes'
import { DashboardRoutes } from './DashboardRoutes'
import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'

const AppRouter = () => {
  const { user, admin } = useAuth()

  return (
    <BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
      <Header />
      <main className="flex-1">
        <Routes>
          <Route
            path="/admin"
            element={
              <PublicRoute isAuthenticated={admin.logged} redirect="/admin/inicio">
                <LoginAdminPage />
              </PublicRoute>
            }
          />
          <Route
            path="/"
            element={
              <PublicRoute isAuthenticated={user.logged}>
                <LoginPage />
              </PublicRoute>
            }
          />
          <Route
            path="/terminos-condiciones"
            element={
              <PublicRoute isAuthenticated={user.logged}>
                <TermsConditionsPage />
              </PublicRoute>
            }
          />
          <Route
            path="/recuperar-contrasena"
            element={
              <PublicRoute>
                <PasswordRecoveryPage />
              </PublicRoute>
            }
          />
          <Route
            path="/verificar-codigo"
            element={
              <PrivateRoute isAuthenticated={user.verified || user.registry || user.changePassword}>
                <CodeVerifyPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/contrasena-usuario"
            element={
              <PrivateRoute isAuthenticated={user.registry || user.changePassword}>
                <UserPasswordPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/recuperar-contrasena-pide"
            element={
              <PrivateRoute isAuthenticated={user.changePassword}>
                <PasswordRecoveryPidePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/*"
            element={
              <PrivateRoute isAuthenticated={admin.logged} redirect="/admin">
                <AdminRoutes />
              </PrivateRoute>
            }
          />
          <Route
            path="/*"
            element={
              <PrivateRoute isAuthenticated={user.logged}>
                <DashboardRoutes />
              </PrivateRoute>
            }
          />
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  )
}

export default AppRouter
