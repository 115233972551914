import React from 'react'
import { Form } from 'react-final-form'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import EmailSendNormalIcon from '../../assets/images/email_send_hover_icon.svg'
import PersonalQuestionsIcon from '../../assets/images/personal_questions_icon.svg'
import CellPhoneSendIcon from '../../assets/images/phone_send_icon.svg'
import UserCheckIcon from '../../assets/images/user_check_icon.svg'
import { hideText } from '../../helpers/helpers'
import { Button } from '../fields/Button'
import SaveButton from '../fields/SaveButton'
import TextField from '../fields/TextField'
import * as validations from '../fields/Validations'
import HeadingTag from '../ui/HeadingTag'
import TextBetweenLines from '../ui/TextBetweenLines'
import Timer from '../ui/Timer'

const CodeVerifyForm = ({
  title,
  subtitle,
  email,
  showEmail = true,
  showTextBetweenLines = true,
  showHomeLink = false,
  bottomButtonText,
  bottomButtonVariant,
  bottomButtonIcon,
  handleSubmitForm,
  handleCodeResend,
  handleBottomButton,
  passwordOptions = false,
  timerConfig,
  setTimerConfig,
  showRecoveryPhone = false,
  handleVerifySms,
  showCodeError = true
}) => {
  return (
    <div className="max-w-lg p-6 mx-auto border border-blue-200 md:text-center md:p-9 shadow-auth-register">
      <HeadingTag title={title} className="text-3xl font-bold" />
      <HeadingTag title={subtitle} as="h2" className="pb-4 pt-3.5 md:pt-4 text-center" />
      <h2 className="pb-4 pt-3.5 md:pt-4 text-center">{subtitle}</h2>
      <p className="font-bold mb-8 text-center">
        {showEmail ? email : hideText(email, 2, !showRecoveryPhone ? 'email' : 'cellphone')}
      </p>
      <Form
        onSubmit={handleSubmitForm}
        subscription={{
          pristine: true,
          submitting: false,
          submitError: showCodeError,
          submitSucceeded: true
        }}
        render={({ handleSubmit, submitError, submitting, pristine, form, submitSucceeded }) => (
          <form onSubmit={handleSubmit} className="mt-7.5 md:mt-6">
            <TextField
              name="code"
              label="Ingresa el código"
              size="full"
              margin="dense"
              labelClassName="text-left mb-2"
              rootClassName="p-5 md:p-7.5 bg-gray-80"
              maxLength={6}
              onKeyPress={validations.validateOnKeyPress('onlyNumber')}
              validate={validations.mix(validations.required(), validations.minLength(6))}
            />
            <div className="pb-7.5 md:pb-7 text-left leading-5">
              {`¿No recibiste el ${!showRecoveryPhone ? 'correo' : 'mensaje de texto'}?`}
              {timerConfig.show && (
                <Timer timerConfig={timerConfig} setTimerConfig={setTimerConfig} timerMessage="para reenviar" />
              )}
              {!timerConfig.show && (
                <span className="pl-1 font-medium text-blue-700 cursor-pointer" onClick={handleCodeResend}>
                  Enviar nuevo código
                </span>
              )}
            </div>
            {submitError && <div className="pb-6 font-bold text-center text-red-500">{submitError}</div>}
            {(submitSucceeded || !showCodeError) && form.reset()}
            {(submitSucceeded || !showCodeError) && form.resetFieldState('code')}
            <div className={clsx({ 'pb-3.75': !showTextBetweenLines })}>
              <SaveButton label="Continuar" size="full" disabled={pristine || submitting} />
            </div>
            {showHomeLink && (
              <div className="pt-4 text-center pb-4">
                <Link className="font-medium text-blue-700" to="/">
                  Volver al inicio
                </Link>
              </div>
            )}
            {showTextBetweenLines && (
              <TextBetweenLines
                content="Otras maneras de iniciar sesión:"
                borderClassName="mb-6 md:mb-3 w-10 md:w-24"
              />
            )}
            {passwordOptions && (
              <div className="flex flex-col items-center justify-center mb-6 border-t-2 border-blue-200 ">
                <p className="mt-6 mb-4">Otras maneras de recuperar contraseña:</p>
                {showRecoveryPhone && (
                  <div
                    className="cursor-pointer font-medium text-blue-700 flex items-center"
                    onClick={() => handleVerifySms(false)}
                  >
                    <ReactSVG src={EmailSendNormalIcon} />
                    <span className="ml-1">Recibe un código a tu correo electrónico registrado</span>
                  </div>
                )}
                {!showRecoveryPhone && (
                  <div
                    className="cursor-pointer font-medium text-blue-700 flex items-center"
                    onClick={() => handleVerifySms(true)}
                  >
                    <ReactSVG src={CellPhoneSendIcon} />
                    <span className="ml-1">Recibe un código a tu número de celular registrado</span>
                  </div>
                )}
                <Link className="font-medium text-blue-700 flex items-center" to="/recuperar-contrasena-pide">
                  <ReactSVG src={PersonalQuestionsIcon} />
                  <span className="ml-1">Valida tu identidad respondiendo preguntas personales</span>
                </Link>
                <Link className="font-medium text-blue-700 flex items-center" to="/">
                  <ReactSVG src={UserCheckIcon} />
                  <span className="ml-1">Valida tu identidad mediante clave SOL</span>
                </Link>
              </div>
            )}
            {bottomButtonText && (
              <Button
                startIcon={bottomButtonIcon}
                size="full"
                variant={bottomButtonVariant}
                onClick={handleBottomButton}
              >
                {bottomButtonText}
              </Button>
            )}
          </form>
        )}
      />
    </div>
  )
}

CodeVerifyForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  email: PropTypes.string,
  showEmail: PropTypes.bool,
  showTextBetweenLines: PropTypes.bool,
  showHomeLink: PropTypes.bool,
  bottomButtonText: PropTypes.string,
  bottomButtonVariant: PropTypes.string,
  bottomButtonIcon: PropTypes.element,
  handleSubmitForm: PropTypes.func,
  handleCodeResend: PropTypes.func,
  handleBottomButton: PropTypes.func,
  passwordOptions: PropTypes.bool,
  timerConfig: PropTypes.object,
  setTimerConfig: PropTypes.func,
  showRecoveryPhone: PropTypes.bool,
  handleVerifySms: PropTypes.func,
  showCodeError: PropTypes.bool
}

export default CodeVerifyForm
