import React from 'react'
import { useNavigate } from 'react-router-dom'

import { request } from '../../api/apiCore'
import { useAuth } from '../../auth/Auth'
import { Container } from '../../components/Container'
import IdentityValidateForm from '../../components/forms/IdentityValidateForm'
import PopUpWindow from '../../components/ui/PopUpWindow'
import { setItemToLocalStorage } from '../../helpers/helpers'
import useShowMessage from '../../hooks/useShowMessage'

export const PasswordRecoveryPidePage = () => {
  const { dispatch, user } = useAuth()
  const navigate = useNavigate()
  const { message, setMessage } = useShowMessage()
  const handleSubmit = async ({ marital_status, names, first_surname, second_surname, ubigeo }) => {
    const data = {
      dni: user.document_number,
      marital_status,
      names,
      first_surname,
      second_surname,
      ubigeo
    }
    try {
      const response = await request({
        url: '/authentication/verify-personal-data',
        method: 'POST',
        data
      })

      if (response.token) {
        dispatch({ type: 'RecoverPasswordPide', payload: { token: response.token } })
        navigate('/contrasena-usuario')
      } else {
        const message = {
          title: 'Ha ocurrido un error',
          content: `<p class="pt-6 pb-7.5">${
            response.description ||
            'Lo sentimos, el servicio no se encuentra disponible por el momento. Por favor, valida tu identidad con otra opción.'
          }</p>`,
          show: true,
          redirect: false
        }
        setItemToLocalStorage('message', JSON.stringify(message))
        navigate('/recuperar-contrasena')
      }
    } catch (error) {
      setMessage({
        ...message,
        title: 'Error',
        content: <span className="pt-6 pb-7.5">{error.response.data.message}</span>,
        show: true
      })
    }
  }

  return (
    <Container className="px-5 pt-8 pb-14 md:px-20 md:pt-14 md:pb-18">
      <IdentityValidateForm
        title="Recuperar contraseña"
        subtitle="Responde a las siguientes preguntas para validar tu identidad:"
        cancelButtonLabel="Volver al inicio"
        handleSubmitForm={handleSubmit}
        handleCancelButton={() => navigate('/')}
      ></IdentityValidateForm>
      {message.show && (
        <PopUpWindow
          title={message.title}
          paperProps={{
            padding: 36,
            borderRadius: 0,
            maxWidth: 482,
            boxShadow: '2px 3px 23px -4px #D3E0EC'
          }}
          showCloseButton
          open={message.show}
          onClose={() => setMessage({ ...message, show: false })}
        >
          {message.content}
        </PopUpWindow>
      )}
    </Container>
  )
}
