import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const useShowMessage = (object = { title: '', content: <span></span>, show: false, redirect: null }) => {
  const [message, setMessage] = useState(object)
  const navigate = useNavigate()

  if (object.redirect) navigate(object.redirect)

  return { message, setMessage }
}

export default useShowMessage
