import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

import clsx from 'clsx'

import InformationAlertIcon from '../../assets/images/danger.svg'
import AutoSave from '../../components/AutoSave'
import { Container } from '../../components/Container'
import MessageCreateOptions from '../../components/admin/MessageCreateOptions'
import MessagesFilters from '../../components/admin/MessagesFilters'
import MessagesHeader from '../../components/admin/MessagesHeader'
import MessagesTable from '../../components/admin/MessagesTable'
import Feedback from '../../components/feedback/Feedback'
import Alert from '../../components/ui/Alert'
import AlertCard from '../../components/ui/AlertCard'
import { Pagination } from '../../components/ui/Pagination'
import RadioTabs from '../../components/ui/RadioTabs'
import { formatMessagesData, routesByType } from '../../configs/messages'
import { getJsonItemFromLocalStorage, setItemToLocalStorage } from '../../helpers/helpers'
import useFilters from '../../hooks/useFilters'
import { useModulesPermissionData } from '../../hooks/useModules'

const radioTabsOptions = [
  { label: 'Notificaciones', value: 'notification' },
  { label: 'Comunicaciones', value: 'communication' }
]

export const MessagesPage = () => {
  const [messages, setMessages] = useState([])
  const [valActionAlert, setActionAlert] = useState(getJsonItemFromLocalStorage('actionAlert'))
  const { status, data: modulesPermission } = useModulesPermissionData()
  const navigate = useNavigate()

  const { onFilter, results, filters, initialFilters } = useFilters('admin/messages.json', {
    sort: 'sent_in desc',
    message_type: 'notification'
  })

  const handleSendMessage = type => {
    setItemToLocalStorage('messageType', type)
    navigate(routesByType[type])
  }

  useEffect(() => {
    if (!results.data?.length) return
    setMessages(formatMessagesData(results.data))
  }, [results])

  if (status !== 'success') return null

  return (
    <Container className="md:h-auto">
      <div className={clsx('px-5 pb-16 md:pb-18 md:px-20', valActionAlert ? 'pt-6' : 'pt-8 md:pt-14')}>
        {valActionAlert && (
          <Alert type={valActionAlert.type} autoHideDuration={5000} setActionAlert={setActionAlert}>
            <span>{`${valActionAlert.text}`}</span>
          </Alert>
        )}
        <section
          className={clsx('pb-7.5 md:pb-0 mb-7.5 md:mb-10 border-b md:border-b-0', {
            'md:flex ': modulesPermission.state
          })}
          aria-labelledby="messages-label"
        >
          <MessagesHeader />
          {!modulesPermission.state ? (
            <AlertCard
              icon={<ReactSVG src={InformationAlertIcon} />}
              content={modulesPermission?.description}
              type="alert"
              className="mt-8 md:mt-14 mb-14 md:mb-18"
            />
          ) : (
            <MessageCreateOptions onClickButton={handleSendMessage} />
          )}
        </section>
        {modulesPermission.state && (
          <Form onSubmit={onFilter} initialValues={initialFilters}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <AutoSave debounce={1000} save={onFilter} />
                <RadioTabs name="message_type" options={radioTabsOptions} tabDefault={initialFilters['message_type']} />
                <div className="md:p-8 md:shadow-admin-dashboard">
                  <MessagesFilters messageType={filters.message_type || 'notification'} />
                  {results.data.length ? (
                    <>
                      <MessagesTable messageType={filters.message_type || 'notification'} tableData={messages} />
                      <Pagination numPages={results.total_pages} />
                    </>
                  ) : (
                    <span className="font-bold flex justify-center pt-8">No se encontraron mensajes</span>
                  )}
                </div>
              </form>
            )}
          </Form>
        )}
      </div>
      <Feedback senderType="official" />
    </Container>
  )
}
