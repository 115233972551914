import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { ReactSVG } from 'react-svg'

import { FORM_ERROR } from 'final-form'
import jwtDecode from 'jwt-decode'

import CancelIcon from '../../assets/images/cancel_icon.svg'
import checkErrorIcon from '../../assets/images/check_error_icon.svg'
import checkIcon from '../../assets/images/check_icon.svg'
import { useAuth } from '../../auth/Auth'
import { Container } from '../../components/Container'
import { Button } from '../../components/fields/Button'
import Checkboxes from '../../components/fields/Checkboxes'
import SaveButton from '../../components/fields/SaveButton'
import HeadingTag from '../../components/ui/HeadingTag'
import { termsOptions } from '../../components/users/TermLabel'
import { getItemFromLocalStorage, removeItemFromLocalStorage } from '../../helpers/helpers'
import { useIdGobPeAcf } from '../../hooks/useIdGobPe'

export const TermsConditionsPage = () => {
  const { dispatch } = useAuth()
  const { logout, getUser } = useIdGobPeAcf()
  const [isError, setIsError] = useState(false)

  const handleSubmitTerms = async ({ terms = [] }) => {
    if (terms.length < termsOptions.length) {
      setIsError(true)
      return { [FORM_ERROR]: 'Debes marcar todas las opciones para poder registrarte' }
    }

    const { token: jwt } = await getUser(getItemFromLocalStorage('idGobPeToken'))
    const { user_id: id, name, father_last_name, document_number, ruc } = jwtDecode(jwt)

    dispatch({ type: 'Login', payload: { id, name, father_last_name, jwt, document_number, ruc, type: 'idGobPe' } })

    removeItemFromLocalStorage(['idGobPeToken'])
    setIsError(false)
  }

  return (
    <Container className="px-5 md:px-20 pt-8 md:pt-14 pb-14 md:pb-18 flex justify-center">
      <Form
        initialValues={{ terms: [] }}
        onSubmit={handleSubmitTerms}
        subscription={{ submitting: true, submitError: true, values: true }}
      >
        {({ handleSubmit, submitting, submitError, values }) => (
          <form
            onSubmit={handleSubmit}
            className="shadow-message-detail-card rounded border border-gray-250 max-w-148 p-6 md:p-9"
          >
            <article className="text-center pb-6">
              <HeadingTag title="Términos y condiciones de uso" className="font-bold text-xl md:text-2xl pb-5" />
              <p>
                Para completar la creación de tu cuenta en Casilla Única Electrónica debes leer y aceptar los siguientes
                documentos:
              </p>
            </article>
            <Checkboxes
              name="terms"
              options={termsOptions}
              parentClassName={isError ? 'pb-1' : 'pb-3.5'}
              baseIcon={<ReactSVG src={isError ? checkErrorIcon : checkIcon} />}
            />
            {submitError && values.terms.length < termsOptions.length && (
              <p className="flex items-center pb-7.5">
                <img src={CancelIcon} alt="error_icon" className="pr-2.5" />
                <span className="text-red-300">{submitError}</span>
              </p>
            )}
            <section className="flex flex-col-reverse md:flex-row items-center max-w-90 mx-auto">
              <Button variant="text" className="!w-16 !mt-4 md:!mt-0 mr-0 md:!mr-10 !underline" onClick={logout}>
                Cancelar
              </Button>
              <SaveButton label="Finalizar el registro" disabled={submitting} />
            </section>
          </form>
        )}
      </Form>
    </Container>
  )
}
