import React from 'react'
import { Form } from 'react-final-form'

import PropTypes from 'prop-types'

import { Button } from '../fields/Button'
import SaveButton from '../fields/SaveButton'
import TextField from '../fields/TextField'
import HeadingTag from '../ui/HeadingTag'
import Timer from '../ui/Timer'

const ProfileEditForm = ({
  title,
  subtitle,
  fieldProps,
  validate,
  showResendSection = false,
  resendText,
  timer,
  setTimer,
  handleResend,
  firstButtonLabel,
  secondButtonLabel,
  handleSubmit,
  showSecondButton = false,
  handleSecondButton
}) => {
  return (
    <section className="max-w-3xl">
      <HeadingTag title={title} className="font-bold text-3xl" />
      <p className="pt-4 pb-7.5 md:pb-6">{subtitle}</p>
      <Form
        onSubmit={handleSubmit}
        subscription={{ pristine: true, submitting: true, submitError: true }}
        render={({ handleSubmit, submitError, submitting, pristine }) => (
          <form onSubmit={handleSubmit} className="max-w-90">
            <TextField {...fieldProps} validate={validate} />
            {submitError && <div className="text-red-500 font-bold text-center pb-6">{submitError}</div>}
            {showResendSection && (
              <div className="mb-6">
                {resendText}
                {timer.show ? (
                  <Timer timerConfig={timer} setTimerConfig={setTimer} timerMessage="para reenviar" />
                ) : (
                  <button className="ml-1 font-medium text-blue-700 underline" onClick={handleResend} type="button">
                    Enviar nuevo código
                  </button>
                )}
              </div>
            )}
            <SaveButton label={firstButtonLabel} className="!mb-4" size="full" disabled={pristine || submitting} />
            {showSecondButton && (
              <Button size="full" variant="text" onClick={handleSecondButton}>
                {secondButtonLabel}
              </Button>
            )}
          </form>
        )}
      />
    </section>
  )
}

ProfileEditForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.node,
  fieldProps: PropTypes.object,
  validate: PropTypes.func,
  showResendSection: PropTypes.bool,
  resendText: PropTypes.string,
  timer: PropTypes.object,
  setTimer: PropTypes.func,
  handleResend: PropTypes.func,
  firstButtonLabel: PropTypes.string,
  secondButtonLabel: PropTypes.string,
  handleSubmit: PropTypes.func,
  showSecondButton: PropTypes.bool,
  handleSecondButton: PropTypes.func
}

export default ProfileEditForm
