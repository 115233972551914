import React, { useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { request } from '../../api/apiCore'
import { useAuth } from '../../auth/Auth'
import { Container } from '../../components/Container'
import UserPasswordForm from '../../components/forms/UserPasswordForm'
import FormsHeader from '../../components/ui/FormsHeader'
import ScrollToTop from '../../components/ui/ScrollToTop'
import { UserFormSteps } from '../../configs/users'
import { getItemFromLocalStorage, removeItemFromLocalStorage, setItemToLocalStorage } from '../../helpers/helpers'

export const UserPasswordPage = () => {
  const { user, dispatch } = useAuth()
  const [pageData, setPageData] = useState({})
  const navigate = useNavigate()
  useEffect(() => setData(), [user])
  const recaptcha_action = 'change_password'
  const { executeRecaptcha } = useGoogleReCaptcha()
  if (!executeRecaptcha) console.log('Execute recaptcha not yet available')

  const setData = () => {
    if (user.registry) {
      setPageData({
        title: 'Creación de contraseña',
        passwordLabel: 'Ingresa tu contraseña',
        submitLabel: 'Crear cuenta'
      })
    }

    if (user.changePassword) {
      setPageData({
        title: 'Recuperación de contraseña',
        passwordLabel: 'Ingresa tu nueva contraseña',
        submitLabel: 'Cambiar contraseña'
      })
    }
  }

  const handleSubmit = async ({ password }) => {
    try {
      if (user.registry) {
        const data = { ...user, password, ruc: getItemFromLocalStorage('userRuc') }
        const { id } = await request({ url: '/users', data, method: 'POST' })

        setItemToLocalStorage(
          'actionAlert',
          JSON.stringify({
            type: id ? 'success' : 'warning',
            text: id ? 'Tu cuenta fue creada exitosamente.' : 'No se pudo crear usuario.'
          })
        )
        handleCancel()
      }

      if (user.changePassword) {
        const recaptcha = await executeRecaptcha(recaptcha_action)

        if (recaptcha) {
          const data = { ...user, password, recaptcha, recaptcha_action }
          const { message } = await request({ url: '/authentication/change-password', data, method: 'POST' })

          if (message === 'Password changed') {
            setItemToLocalStorage(
              'actionAlert',
              JSON.stringify({ text: 'Se actualizó la contraseña exitosamente.', type: 'success' })
            )
          } else {
            setItemToLocalStorage(
              'actionAlert',
              JSON.stringify({ text: 'No se pudo actualizar su contraseña.', type: 'warning' })
            )
          }
          navigate('/')
        }
      }
    } catch (error) {
      return { [FORM_ERROR]: `Ocurrió un error: ${error.response.data.message}` }
    }
  }

  const handleCancel = () => {
    removeItemFromLocalStorage(['userRegistryData', 'userRuc'])
    dispatch({ type: 'Logout' })
  }

  return (
    <Container className="px-5 pt-8 2xl:h-screen pb-14 md:px-20 md:pt-14 md:pb-18">
      {user.registry && (
        <FormsHeader
          title="Creando mi cuenta de carpeta ciudadana"
          steps={UserFormSteps}
          activeStep={UserFormSteps.SET_PASSWORD}
        />
      )}
      <UserPasswordForm
        title={pageData.title}
        passwordFieldLabel={pageData.passwordLabel}
        submitButtonLabel={pageData.submitLabel}
        handleSubmitForm={handleSubmit}
        handleCancelForm={handleCancel}
      />
      <ScrollToTop />
    </Container>
  )
}
