import React from 'react'

import { appName, isAdminRoute, isMailBoxEnv } from '../../helpers/helpers'
import { Container } from '../Container'
import { UserMenu } from './UserMenu'

export const Header = () => (
  <header className="bg-red-750 py-3.75">
    <Container className="px-5 md:px-20 flex items-center">
      <a href={isAdminRoute ? '/admin' : '/'}>
        <img
          src="https://cdn.facilita.gob.pe/packs/media/images/escudo_text_gobpe_bicentenario-b88b06404c2de3e157291c4d4e8dd89b.svg"
          className="max-w-9 h-10"
        />
      </a>
      <div className="md:bg-white md:w-px mx-6 h-6 hidden md:block"></div>
      <p className="text-white hidden md:block text-sm leading-4">{appName}</p>
      {!isMailBoxEnv && (
        <a
          className="px-2 ml-4 bg-white text-red-750 text-sm font-bold rounded"
          href="https://www.gob.pe/26621-plataforma-digital-carpeta-ciudadana"
          target="_blank"
          rel="noreferrer"
        >
          BETA
        </a>
      )}
      <UserMenu />
    </Container>
  </header>
)
