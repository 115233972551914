import React, { useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'
import jwtDecode from 'jwt-decode'

import { request } from '../api/apiCore'
import { useAuth } from '../auth/Auth'
import { Container } from '../components/Container'
import LoginForm from '../components/forms/LoginForm'
import Loader from '../components/ui/Loader'
import CitizenFolderLogin from '../components/users/CitizenFolderLogin'
import MailBoxLogin from '../components/users/MailBoxLogin'
import { APP_ENV } from '../constants/globals'
import { setItemToLocalStorage } from '../helpers/helpers'
import { useIdGobPeAcf } from '../hooks/useIdGobPe'

const loginTypes = (auth, onSubmit) => ({
  'mail-box': {
    component: <MailBoxLogin handleLogin={auth} />,
    show: ['casilla']
  },
  'citizen-folder': {
    component: <CitizenFolderLogin handleLogin={auth} />,
    show: ['qa', 'production']
  },
  'form': {
    component: <LoginForm handleSubmitForm={onSubmit} handleIdGobPeButton={auth} />,
    show: ['staging', 'development']
  }
})

export const LoginPage = () => {
  const { dispatch } = useAuth()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { auth, getToken, getUser } = useIdGobPeAcf()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const onSubmit = async values => {
    try {
      const reCaptcha = await executeRecaptcha('login')

      if (reCaptcha) {
        const data = { document_type_code: 'dni', recaptcha: reCaptcha, ...values }
        const authenticate = await request({ url: '/authentication', method: 'POST', data })

        if (authenticate.response?.data.error) return { [FORM_ERROR]: authenticate.response.data.error }

        const { token: jwt } = authenticate
        const { user_id: id, name, father_last_name, document_number, ruc } = jwtDecode(jwt)

        dispatch({ type: 'Login', payload: { id, name, father_last_name, jwt, document_number, ruc, type: 'cue' } })
      }
    } catch (error) {
      return { [FORM_ERROR]: `Ocurrió un error: ${error}` }
    }
  }

  const login = async () => {
    const search = new URLSearchParams(location.search)
    const idGobPeCode = search.get('code')

    if (idGobPeCode) {
      setIsLoading(true)

      const { token: idGobPeToken, id_token: idGobPeIdToken } = await getToken(idGobPeCode)
      const { doc } = jwtDecode(idGobPeIdToken)

      const response = await request({ url: `/users/verify-user?document_number=${doc}` })

      if (response) {
        const { token: jwt } = await getUser(idGobPeToken)
        const { user_id: id, name, father_last_name, document_number, ruc } = jwtDecode(jwt)

        dispatch({
          type: 'Login',
          payload: { id, name, father_last_name, jwt, document_number, ruc, type: 'idGobPe' }
        })
      } else {
        setItemToLocalStorage('idGobPeToken', idGobPeToken)
        navigate('/terminos-condiciones')
      }
    }
  }

  useEffect(() => {
    login()
  }, [location.search])

  if (isLoading) return <Loader message="Espera un momento, estamos validando los datos ingresados." />

  return (
    <Container className="px-5 md:px-20">
      {Object.values(loginTypes(auth, onSubmit)).find(item => item.show.includes(APP_ENV))['component']}
    </Container>
  )
}
