import { useEffect } from 'react'

import { request } from '../api/apiCore'
import {
  ID_GOB_PE_ACR_VALUE,
  ID_GOB_PE_BASE_URL,
  ID_GOB_PE_CLIENT_ID,
  ID_GOB_PE_PROFILE_URI,
  ID_GOB_PE_REDIRECT_URI,
  ID_GOB_PE_RESPONSE_TYPE,
  ID_GOB_PE_SCOPE,
  ID_GOB_PE_STATE
} from '../constants/globals'
import { useScript } from './useScript'

export const useIdGobPe = () => {
  const status = useScript(import.meta.env.VITE_ID_GOB_PE_SCRIPT)
  const acrType = import.meta.env.VITE_ID_GOB_PE_ACR_TYPE

  useEffect(() => {
    if (status === 'ready') {
      const acrValue =
        acrType === 'ONE_FACTOR'
          ? IDGobPeConst.ACR_ONE_FACTOR
          : acrType === 'TWO_FACTOR'
          ? IDGobPeConst.ACR_TWO_FACTOR
          : IDGobPeConst.ACR_CERTIFICATE_DNIE

      IDGobPe.init({
        clientId: import.meta.env.VITE_ID_GOB_PE_CLIENT,
        responseTypes: [IDGobPeConst.RESPONSE_TYPE_ID_TOKEN, IDGobPeConst.RESPONSE_TYPE_TOKEN],
        scopes: [IDGobPeConst.SCOPE_PROFILE],
        acr: [acrValue]
      })
    }
  }, [status])

  const auth = () => IDGobPe.auth()

  const onSuccess = response => IDGobPe.onSuccess(response)

  const onError = error => IDGobPe.onError(console.log('ID Gob.pe error:', error))

  return { status, auth, onSuccess, onError }
}

export const useIdGobPeAcf = () => {
  const authUri = `${ID_GOB_PE_BASE_URL}?client_id=${ID_GOB_PE_CLIENT_ID}&response_type=${ID_GOB_PE_RESPONSE_TYPE}&redirect_uri=${ID_GOB_PE_REDIRECT_URI}&acr_values=${ID_GOB_PE_ACR_VALUE}&scope=${ID_GOB_PE_SCOPE}&state=${ID_GOB_PE_STATE}`
  const logoutUri = `${ID_GOB_PE_BASE_URL}?post_logout_redirect_uri=${ID_GOB_PE_REDIRECT_URI}`

  const auth = () => (location.href = authUri)

  const getToken = async code => await request({ url: '/idgobpe/token.json', data: { code }, method: 'POST' })

  const getUser = async token => await request({ url: `/idgobpe/user?token=${token}` })

  const logout = () => (location.href = logoutUri)

  const profile = () => open(ID_GOB_PE_PROFILE_URI, '_blank')

  return { auth, getToken, getUser, logout, profile }
}
