import React from 'react'

import GetAppIcon from '@material-ui/icons/GetApp'

import MessageContentFile from '../components/admin/MessageContentFile'
import MessageReviewFile from '../components/admin/MessageReviewFile'
import * as validations from '../components/fields/Validations'
import { MessageContentButton } from '../components/notifications/MessageContentButton'
import Tag from '../components/ui/Tag'
import { MAX_LENGTH_MESSAGE_SUBJECT_TABLE, MESSAGE_STATUS } from '../constants/globals'
import { documentDownload, truncateText } from '../helpers/helpers'

/* === ADMIN === */

export const officialMessageTableHeaderCell = {
  notification: ['Nro. seguimiento', 'Destinatario', 'Estado', 'Asunto', 'Fecha de envío', 'Constancia'],
  communication: ['Destinatario', 'Área competente del pronunciamiento', 'Asunto', 'Fecha de envío']
}

export const receiptsTableHeaderCells = [
  '#',
  'Tipo de documento',
  'Número de documento',
  'Apellidos',
  'Nombres',
  'Acciones'
]

export const MessageCreateSteps = {
  SET_USER_INFO: {
    label: 'Datos del destinatario'
  },
  SET_MESSAGE_INFO: {
    label: 'Ingresa la información y contenido del mensaje'
  },
  PREVIEW: {
    notification: 'Revisa la información de la notificación a enviar',
    communication: 'Revisa la información de la comunicación a enviar'
  }
}

export const messageContentByStatus = message => {
  const downloadUrl = `admin/messages/${message.id}.pdf`

  const sendContent = (
    <>
      <div className="pb-4 text-gray-500 !text-sm !font-normal">{message.sent_in}</div>
      <MessageContentButton
        buttonClassName="px-5 py-2.25 bg-gray-40"
        handleButton={() => documentDownload(`${downloadUrl}?export_type=sender`)}
        text="Descargar constancia"
        textClassName="font-bold"
      />
    </>
  )

  const readContent = message.is_read ? (
    <>
      <div className="pb-4 text-gray-500 !text-sm !font-normal">{message.readed_at}</div>
      <MessageContentButton
        buttonClassName="px-5 py-2.25 bg-gray-40"
        handleButton={() => documentDownload(`${downloadUrl}?export_type=receipt`)}
        text="Descargar acuse de recibo"
        textClassName="font-bold"
      />
    </>
  ) : (
    <span>Pendiente</span>
  )

  return [sendContent, readContent]
}

export const messageStatus = {
  SEND: {
    label: 'ENVIADO',
    state: 'new'
  },
  READ: {
    label: 'DEPOSITADA',
    state: 'read'
  }
}

export const tagConfig = {
  notification: {
    type: 'success',
    description: 'Notificación'
  },
  communication: {
    type: 'luxury',
    description: 'Comunicación'
  },
  sent: {
    type: 'success',
    description: 'Enviado'
  },
  read: {
    type: 'luxury',
    description: 'Leído'
  }
}

const getMessageDataByType = ({ id, area_name, sent_in, track_code, is_read, subject, message_type, user_name }) => {
  const { exportType, documentType } = MESSAGE_STATUS[is_read ? 'read' : 'unread']

  if (message_type === 'notification') {
    const stateType = is_read ? 'read' : 'sent'

    return {
      id,
      track_code,
      addressee: user_name,
      state: <Tag {...tagConfig[stateType]} />,
      subject: truncateText(subject, MAX_LENGTH_MESSAGE_SUBJECT_TABLE),
      sent_in,
      constancy: (
        <div
          className="font-medium text-blue-700 cursor-pointer"
          onClick={() => documentDownload(`admin/messages/${id}.pdf?export_type=${exportType}`)}
        >
          <GetAppIcon className="mr-3" />
          <span>{documentType}</span>
        </div>
      )
    }
  }

  if (message_type === 'communication') {
    return {
      id,
      addressee: user_name,
      area_name,
      subject: truncateText(subject, MAX_LENGTH_MESSAGE_SUBJECT_TABLE),
      sent_in
    }
  }
}

export const formatMessagesData = messages => messages.map(message => getMessageDataByType(message))

export const messageInfoPageConfig = {
  notification: {
    breadcrumbText: 'Nueva notificación',
    breadcrumbKey: 'notifications',
    headerTitle: 'Enviar una notificación',
    trackCodeLabel: 'Documento y número de seguimiento',
    trackCodeSelectValidate: validations.mix(validations.required()),
    trackCodeTextValidate: validations.required()
  },
  communication: {
    breadcrumbText: 'Nueva comunicación',
    breadcrumbKey: 'communications',
    headerTitle: 'Enviar una comunicación',
    trackCodeLabel: 'Documento y número de seguimiento (opcional)',
    trackCodeSelectValidate: null,
    trackCodeTextValidate: null
  }
}

export const routesByType = {
  notification: '/admin/datos-destinatarios',
  communication: '/admin/datos-destinatario'
}

/* === CITIZEN === */

export const addresseMessageContent = {
  recipient_type: 'Tipo de destinatario: ',
  user_document_type_code: 'Tipo de documento de identidad: ',
  citizen_id_number: 'Número de DNI: ',
  user_father_last_name: 'Apellido paterno: ',
  user_mother_last_name: 'Apellido materno: ',
  user_name: 'Nombres: '
}

export const addresseMessageReview = {
  citizen_id_code: 'Tipo de documento de identidad: ',
  citizen_id_number: 'Número de DNI: ',
  last_names: 'Apellidos: ',
  first_names: 'Nombres: '
}

const baseInfoMessage = {
  track_code_type_name: 'Documento y número de seguimiento: ',
  procedure_name: 'Nombre del trámite: ',
  subject: 'Asunto: ',
  content: 'Contenido del mensaje: '
}

export const infoMessageContent = { message_type_description: 'Tipo de mensaje: ', ...baseInfoMessage }

export const infoMessageReview = { area_name: 'Área competente del pronunciamiento: ', ...baseInfoMessage }

export const senderDataLabels = {
  notification: {
    area_name: 'Área competente del pronunciamiento: '
  },
  communication: {
    area_name: 'Área competente del pronunciamiento: ',
    entity_ruc: 'RUC de la entidad: '
  }
}

const getFile = file => {
  return { 'message-content': <MessageContentFile file={file} />, 'message-review': <MessageReviewFile file={file} /> }
}

export const getAttachedFiles = (files, type, component) =>
  files?.map((file, index) => ({
    name:
      type === 'notification' ? (index === 0 ? 'Documento principal:' : `Adjunto ${index}:`) : `Adjunto ${index + 1}:`,
    content: getFile(file)[component]
  })) || []
