import React from 'react'

import FilterListIcon from '@material-ui/icons/FilterList'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import DateField from '../fields/DateField'
import SearchField from '../fields/SearchField'
import SelectField from '../fields/SelectField'
import * as validations from '../fields/Validations'

const messagesStates = [
  { value: '', label: 'Todos' },
  { value: 'new', label: 'Enviado' },
  { value: 'read', label: 'Leído por el destinatario' }
]

const orderOptions = [
  { value: 'sent_in desc', label: 'Más recientes primero' },
  { value: 'sent_in asc', label: 'Más antiguos primero' }
]

const MessagesFilters = ({ messageType }) => {
  return (
    <div className="border-b border-blue-200">
      <section className="bg-gray-80 p-5 md:p-6 mt-5 md:mt-0">
        <h2 className="text-blue-700 font-bold pb-5 hidden md:block">
          <FilterListIcon className="mr-4" />
          Filtros de búsqueda
        </h2>
        <div className="md:flex">
          {messageType === 'notification' && (
            <SelectField
              containerClassName="md:w-2/5 mb-5 md:mb-0"
              name="state"
              label="Estado"
              defaultOption=""
              orientation="horizontal"
              options={messagesStates}
            />
          )}
          <div className={clsx('md:flex items-center', { 'justify-end md:w-3/5': messageType === 'notification' })}>
            <legend className="text-base font-bold text-gray-800 pr-4 mb-2 md:mb-0">Fecha de envío</legend>
            <DateField
              name="sent_in_from"
              placeholder="Desde"
              margin="none"
              size="md"
              validate={validations.isDate()}
            />
            <span className="md:border-2 border-black mx-3 w-3"></span>
            <DateField name="sent_in_to" placeholder="Hasta" margin="none" size="md" validate={validations.isDate()} />
          </div>
        </div>
      </section>
      <section className="md:flex justify-between mt-5 md:mt-8">
        <SearchField name="search" placeholder="Buscar por palabra clave" />
        <SelectField name="sort" label="Ordenar por:" orientation="horizontal" options={orderOptions} />
      </section>
    </div>
  )
}

MessagesFilters.propTypes = {
  messageType: PropTypes.string
}

export default MessagesFilters
