import React from 'react'

import PropTypes from 'prop-types'

import {
  addresseMessageContent,
  getAttachedFiles,
  infoMessageContent,
  messageContentByStatus,
  messageStatus,
  senderDataLabels
} from '../../configs/messages'
import { formatLabelsObject } from '../../helpers/helpers'
import { Container } from '../Container'
import HeadingTag from '../ui/HeadingTag'
import StatusTrack from '../ui/StateTrack'
import MessageContentSection from './MessageContentSection'
import MessageReviewContent from './MessageReviewContent'

const SubjectTag = ({ text }) => (
  <div className="bg-purple-100 w-fit text-purple-200 md:text-xl py-2 md:py-2.25 px-3 md:px-3.75">{text}</div>
)

const NotificationMessageContent = ({ message }) => (
  <>
    <h2 className="text-xl md:text-2xl pb-5 md:pb-7 font-bold">Estado de la notificación</h2>
    <StatusTrack steps={messageStatus} activeStep={message.state} stepContent={messageContentByStatus(message)} />
    <div className="pt-4 mt-6 md:mt-8 border-gray-50 border-t-2">
      <p className="font-bold pb-1">Enviado por {message.gob_user_name}</p>
      {message.area_name}
    </div>
  </>
)

const CommunicationMessageContent = ({ message }) => (
  <>
    <p className="font-bold">Enviado por:</p>
    <p>
      {message.gob_user_name} - {message.area_name}{' '}
    </p>
    <p className="font-bold pt-4">Fecha y hora de envío:</p>
    <p>{message.sent_in} </p>
  </>
)

const getAsideContent = message => {
  return {
    'notification': <NotificationMessageContent message={message} />,
    'communication': <CommunicationMessageContent message={message} />
  }
}

const MessageContent = ({ message }) => (
  <Container className="pt-8 md:pt-14">
    <div className="font-bold px-5 md:px-20">
      <HeadingTag
        title={`${message.user_name} ${message.user_father_last_name} ${message.user_mother_last_name}`}
        className="font-bold text-2xl md:text-3xl pb-4 md:pb-6"
      />
      <SubjectTag
        text={message.message_type === 'notification' ? `N. Exp ${message.track_code || ''}` : message.subject}
      />
    </div>
    <div className="md:flex mt-7.5 md:mt-10 bg-gray-80 px-5 md:px-20 pt-6 md:pt-10 pb-14 md:pb-18">
      <aside className="p-6 md:p-10 bg-white border border-blue-200 shadow-auth-register md:w-1/3">
        {getAsideContent(message)[message.message_type]}
      </aside>
      <article className="p-6 md:p-10 md:ml-5 mt-4 md:mt-0 bg-white border border-blue-200 shadow-auth-register md:w-2/3">
        <MessageContentSection
          title="Datos del destinatario"
          sectionData={formatLabelsObject(addresseMessageContent, { ...message, recipient_type: 'Persona natural' })}
          className="md:grid grid-cols-2 gap-2.5"
        />
        <MessageContentSection
          title="Datos del remitente"
          sectionData={formatLabelsObject(senderDataLabels[message.message_type], message)}
          className="md:grid grid-cols-1 gap-2.5"
        />
        <section className="border-blue-200">
          <h2 className="text-xl md:text-2xl pb-4 md:pb-5 font-bold">Información y contenido del mensaje</h2>
          <div className="grid grid-cols-1 gap-2.5">
            {formatLabelsObject(infoMessageContent, message).map((data, index) => (
              <MessageReviewContent key={index} data={data} />
            ))}
          </div>
          {getAttachedFiles(message.files, message.message_type, 'message-content').length > 0 && (
            <>
              <div className="font-bold pt-4 md:pt-4.5 pb-3">Enlaces adjuntos</div>
              <div className="grid grid-cols-1 gap-4">
                {getAttachedFiles(message.files, message.message_type, 'message-content').map((data, index) => (
                  <MessageReviewContent key={index} data={data} classNameRoot="ml-2 md:ml-4" />
                ))}
              </div>
            </>
          )}
        </section>
      </article>
    </div>
  </Container>
)

MessageContent.propTypes = {
  message: PropTypes.object
}

NotificationMessageContent.propTypes = {
  message: PropTypes.object
}

CommunicationMessageContent.propTypes = {
  message: PropTypes.object
}

SubjectTag.propTypes = {
  text: PropTypes.string
}

export default MessageContent
