import React from 'react'
import { useField } from 'react-final-form'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import Error from './Error'
import { marginSizes } from '../../utils/sizes'

const useStyles = makeStyles({
  root: {
    color: '#26292E',
    '&$checked': {
      color: '#26292E'
    }
  },
  checked: {}
})

const RadioField = ({
  label,
  name,
  validate,
  options = [],
  disabled = false,
  containerClassName = '',
  margin = 'normal',
  placement = 'end',
  formContolLabelClasses,
  ...props
}) => {
  const { input } = useField(name, { validate, ...props })
  const classes = useStyles()

  return (
    <div className="relative">
      <fieldset className={clsx(containerClassName, marginSizes[margin])}>
        <legend className="font-bold mb-2">{label}</legend>
        <RadioGroup
          aria-label={label}
          name={name}
          {...input}
          onChange={e => {
            input.onChange(e.target.value)
            props.onChange && props.onChange(e.target.value)
          }}
        >
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value.toString()}
              control={<Radio disabled={disabled} classes={{ root: classes.root, checked: classes.checked }} />}
              classes={{ root: formContolLabelClasses?.formContolLabel }}
              label={option.label}
              labelPlacement={placement}
            />
          ))}
        </RadioGroup>
        <Error name={name} className="absolute -bottom-4" />
      </fieldset>
    </div>
  )
}

export default RadioField

RadioField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  validate: PropTypes.func,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  containerClassName: PropTypes.string,
  margin: PropTypes.string,
  onChange: PropTypes.func,
  placement: PropTypes.string,
  formContolLabelClasses: PropTypes.object
}
