import React, { useState } from 'react'
import { ReactSVG } from 'react-svg'

import PropTypes from 'prop-types'

import DangerAlertIcon from '../../assets/images/danger.svg'
import DownloadIcon from '../../assets/images/download_icon.svg'
import ExternalLinkIcon from '../../assets/images/external_link_icon.svg'
import { useAuth } from '../../auth/Auth'
import RucNumberForm from '../../components/forms/RucNumberForm'
import PopUpWindow from '../../components/ui/PopUpWindow'
import { Button } from '../fields/Button'
import AlertCard from '../ui/AlertCard'
import HeadingTag from '../ui/HeadingTag'
import LoadButton from '../ui/LoadButton'
import TagButton from '../ui/TagButton'
import DocumentDetailTable from './DocumentDetailTable'

const paperStyles = {
  style: {
    boxShadow: '2px 3px 23px -4px #D3E0EC',
    maxWidth: '27rem'
  }
}

const RucRegisterSection = ({ documentNumber, onSubmitDialog }) => {
  const [openDialog, setOpenDialog] = useState(false)

  return (
    <>
      <AlertCard
        icon={<ReactSVG src={DangerAlertIcon} />}
        title="Importante: "
        content="Para descargar este documento debes registrar tu número de RUC."
        type="alert"
        className="md:max-w-3xl mb-6"
        titleHidden={false}
      />
      <Button
        type="button"
        variant="secondary"
        className="!py-4 md:!px-12 !max-w-full md:!max-w-max"
        onClick={() => setOpenDialog(true)}
      >
        Registrar RUC
      </Button>
      <PopUpWindow
        title="Registra tu RUC"
        titleClassName="mx-6 md:mx-9 pt-6 md:pt-9 !pb-4 md:!pb-6"
        paperProps={{ ...paperStyles }}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <div className="px-6 md:px-9 pb-6 md:pb-9 pt-4 md:pt-6">
          <RucNumberForm
            subtitle="Para acceder a este documento debes registrar tu número de RUC. Podrás editarlo en la sección Mi perfil."
            labelClassName="pt-4"
            initialValues={{ document_number: documentNumber }}
            handleSubmit={onSubmitDialog}
            showModalOptions
            handleCancel={() => setOpenDialog(false)}
          />
        </div>
      </PopUpWindow>
    </>
  )
}

const DocumentGenerated = ({ documentData, userData, onSubmitDialog }) => {
  const allowDownload = documentData.is_pide ? (documentData.need_ruc && !userData.ruc ? false : true) : true
  const { user } = useAuth()

  return (
    <section>
      <div className="border-b pb-8 mb-8 md:pb-10 md:mb-10">
        <HeadingTag title={documentData.name} className="font-bold text-3xl md:text-4xl md:pr-10" />
        <p className="pt-4 md:pt-5">{documentData.description}</p>
        <p className={`pt-3 font-medium ${documentData.is_pide && 'pb-6'}`}>
          Emitido por {documentData.institution_name} {documentData.institution_abbreviation}
        </p>
        {allowDownload ? (
          <>
            {!documentData.is_pide && (
              <DocumentDetailTable tableTitle="Detalles del documento" documentData={documentData} />
            )}
            <LoadButton
              initialIcon={DownloadIcon}
              text="Descargar documento"
              buttonClassName="hover:bg-gray-80 focus:bg-blue-100 font-bold px-6 py-3.5 w-full md:w-auto"
              url={`/documents_user/${documentData.id}.pdf?document_number=${user.document_number}`}
            />
          </>
        ) : (
          <RucRegisterSection documentNumber={userData.document_number} onSubmitDialog={onSubmitDialog} />
        )}
        {documentData.has_expiration_date && (
          <>
            <p className="pt-8 pb-5">
              El trámite para renovar o volver a solicitar este documento se realiza directamente en la web de la
              entidad.
            </p>
            <TagButton
              label="Ir al servicio"
              className="px-5 md:px-4 flex justify-between"
              icon={<ReactSVG src={ExternalLinkIcon} className="pl-2" />}
              urlPath={documentData.service_link}
            />
          </>
        )}
      </div>
    </section>
  )
}

RucRegisterSection.propTypes = {
  documentNumber: PropTypes.string,
  onSubmitDialog: PropTypes.func
}

DocumentGenerated.propTypes = {
  documentData: PropTypes.object,
  document_number: PropTypes.string,
  userData: PropTypes.object,
  onSubmitDialog: PropTypes.func
}

export default DocumentGenerated
