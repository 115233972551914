import React from 'react'
import { useEffect, useState } from 'react'

import { request } from '../api/apiCore'
import { convertToDni, isNaturalPersonsRUC } from '../helpers/helpers'

const useSolKey = () => {
  const search = new URLSearchParams(location.search)
  const solKeyCode = search.get('code')
  const [result, setResult] = useState({
    error: false,
    message: '',
    documentNumber: '',
    ruc: '',
    isAuthenticated: solKeyCode ? true : false
  })

  useEffect(() => {
    if (solKeyCode) {
      try {
        request({
          url: '/authentication/verify-code-sunat',
          method: 'POST',
          data: { code: solKeyCode }
        }).then(({ ruc }) => {
          if (ruc) {
            if (isNaturalPersonsRUC(ruc)) {
              setResult({ ...result, documentNumber: convertToDni(ruc), ruc })
            } else {
              setResult({ ...result, error: true, message: findMessage(100).message })
            }
          } else {
            setResult({ ...result, error: true, message: findMessage(503).message })
          }
        })
      } catch (error) {
        setResult({ ...result, error: true, message: findMessage(503).message })
      }
    }
  }, [location.search])

  return result
}

const findMessage = type => solKeyMessages.find(message => message.type === type)

const solKeyMessages = [
  {
    type: 100,
    message: (
      <div className="pt-6 pb-7.5">
        <p className="pb-3">
          Actualmente este servicio solo está disponible para ciudadanos peruanos con RUC de persona natural.
        </p>
        <p>
          El formato de RUC aceptado inicia con el número “10”, seguido de los 8 dígitos del documento nacional de
          identidad (DNI).
        </p>
      </div>
    )
  },
  {
    type: 503,
    message: (
      <div className="pt-6 pb-7.5">
        <p>Ocurrió un problema en el proceso de validación. Por favor, intenta nuevamente.</p>
      </div>
    )
  }
]

export default useSolKey
