import React from 'react'
import { Form } from 'react-final-form'

import PropTypes from 'prop-types'

import { Button } from '../fields/Button'
import SaveButton from '../fields/SaveButton'
import TextField from '../fields/TextField'
import * as validations from '../fields/Validations'
import HeadingTag from '../ui/HeadingTag'

const PasswordRecoveryForm = ({ handleSubmitForm, handleCancelButton }) => {
  return (
    <div className="max-w-lg p-6 mx-auto border border-blue-200 md:text-center md:p-9 shadow-auth-register">
      <HeadingTag title="Recuperar contraseña" className="text-2xl font-bold mb-6" />
      <Form
        onSubmit={handleSubmitForm}
        subscription={{ pristine: true, submitting: true }}
        render={({ handleSubmit, submitError, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              name="document_number"
              label="Ingresa tu número de DNI"
              size="full"
              margin="none"
              rootClassName="pb-7.5 md:pb-7"
              labelClassName="text-left mb-2"
              validate={validations.required()}
            />
            {submitError && <div className="pb-6 font-bold text-center text-red-500">{submitError}</div>}
            <div className="pb-3.75">
              <SaveButton label="Continuar" size="full" disabled={pristine || submitting} />
            </div>
            <Button size="full" variant="text" onClick={handleCancelButton}>
              Regresar
            </Button>
          </form>
        )}
      />
    </div>
  )
}

PasswordRecoveryForm.propTypes = {
  handleSubmitForm: PropTypes.func,
  handleCancelButton: PropTypes.func
}

export default PasswordRecoveryForm
