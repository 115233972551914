import React from 'react'
import { ReactSVG } from 'react-svg'

import PropTypes from 'prop-types'

import InformationAlertIcon from '../../assets/images/information_alert_icon.svg'
import { appName } from '../../helpers/helpers'
import AlertCard from '../ui/AlertCard'
import KnowMore from './KnowMore'
import LoginCard from './LoginCard'
import LoginHeader from './LoginHeader'

const CitizenFolderLogin = ({ handleLogin }) => (
  <div className="pt-8 md:pt-14 pb-14 md:pb-18 m-auto max-w-xl">
    <LoginHeader
      title={appName}
      subtitle="Revisa tus documentos, el avance de tus trámites y las notificaciones que te envían las entidades públicas."
      className="pb-7.5 md:pb-10 md:text-center"
    />
    <LoginCard className="mb-8" handleLoginButton={handleLogin} showInfo={false} />
    <AlertCard
      icon={<ReactSVG src={InformationAlertIcon} />}
      title="Importante"
      content="El servicio se encuentra en fase piloto Beta para verificar su correcto funcionamiento e identificar oportunidades de mejora. Actualmente, puedes registrarte si eres un ciudadano peruano mayor de 18 años."
      type="info"
      className="md:font-medium text-left mb-6"
    />
    <div className="text-center">
      <KnowMore
        linkPath="https://www.gob.pe/26621-plataforma-digital-carpeta-ciudadana"
        text="Conoce más de Carpeta Ciudadana"
      />
    </div>
  </div>
)

CitizenFolderLogin.propTypes = {
  handleLogin: PropTypes.func
}

export default CitizenFolderLogin
