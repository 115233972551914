import axios from 'axios'

import { UNAUTHORIZED } from '../constants/globals'
import { getJsonItemFromLocalStorage, isAdminRoute, removeItemFromLocalStorage } from '../helpers/helpers'

const backendApiConfig = axios.create({ baseURL: '/api/v1', headers: { 'Content-type': 'application/json' } })

const requestInterceptor = () => {
  backendApiConfig.interceptors.request.use(config => {
    if (!isAdminRoute) {
      const { jwt } = getJsonItemFromLocalStorage('userData') || null
      config.headers['Authorization'] = `Bearer ${jwt}`
    }
    return config
  })
}

const responseInterceptor = () => {
  backendApiConfig.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === UNAUTHORIZED) {
        if (isAdminRoute) {
          removeItemFromLocalStorage(['adminData'])
        } else {
          removeItemFromLocalStorage(['userData'])
        }
        location = isAdminRoute ? '/admin' : '/'
      }
      return Promise.reject(error)
    }
  )
}

export const request = ({ ...options }) => {
  requestInterceptor()
  responseInterceptor()

  const onSuccess = response => response.data
  const onError = error => error

  return backendApiConfig(options).then(onSuccess).catch(onError)
}

export const apiGetPdfRequest = async pathname => {
  requestInterceptor()

  const response = await backendApiConfig.get(pathname, {
    responseType: 'arraybuffer',
    headers: { 'Accept': 'application/pdf' }
  })

  return response
}

export const solKeyUrl = redirectPage => {
  const rootUrl = import.meta.env.VITE_ROOT_URL
  const solKeyUrlForCitizenFolder = import.meta.env.VITE_SOL_URL
  const redirectUri = redirectPage ? `${rootUrl}/${redirectPage}` : rootUrl
  window.location = `${solKeyUrlForCitizenFolder}?&redirect_uri=${redirectUri}`
}

export const axiosRequest = async ({ ...options }) => await axios.request(options)
