import React from 'react'

import HeadingTag from '../ui/HeadingTag'

const MessagesHeader = () => (
  <div className="md:w-4/5 md:pr-5">
    <HeadingTag
      title="Notificaciones y comunicaciones"
      className="font-bold text-3xl md:text-4xl"
      id="messages-label"
    />
    <p className="mt-4 md:mt-6">
      En esta sección podrás enviar notificaciones y comunicaciones a los ciudadanos que tengan trámites o procesos
      abiertos en tu entidad.
    </p>
  </div>
)

export default MessagesHeader
