import React from 'react'

import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'

import { Button } from '../fields/Button'

const MessageCreateOptions = ({ onClickButton }) => (
  <div className="md:w-1/5 mt-6 md:mt-0">
    <div className="pb-4">
      <Button size="full" startIcon={<AddIcon />} onClick={() => onClickButton('notification')}>
        Enviar notificación
      </Button>
    </div>
    <Button size="full" variant="text" startIcon={<AddIcon />} onClick={() => onClickButton('communication')}>
      Enviar comunicación
    </Button>
  </div>
)

MessageCreateOptions.propTypes = {
  onClickButton: PropTypes.func
}

export default MessageCreateOptions
