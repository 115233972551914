import React from 'react'
import { ReactSVG } from 'react-svg'

import { Dialog } from '@material-ui/core'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import CloseIcon from '../../assets/images/close_icon.svg'
import { Button } from '../fields/Button'

const PopUpWindow = ({ title, children, paperProps, showCloseButton, open, onClose, titleClassName = '' }) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth PaperProps={paperProps}>
      <h2
        className={clsx(
          titleClassName,
          'flex items-center justify-between pb-6 text-xl font-bold border-b border-blue-200'
        )}
      >
        {title}
        <button onClick={onClose}>
          <ReactSVG src={CloseIcon} />
        </button>
      </h2>
      {React.isValidElement(children) ? (
        children
      ) : (
        <div className="hint-html-injected" dangerouslySetInnerHTML={{ __html: children }} />
      )}
      {showCloseButton && (
        <div className="flex justify-center">
          <Button onClick={onClose} size="m">
            Cerrar
          </Button>
        </div>
      )}
    </Dialog>
  )
}

PopUpWindow.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  paperProps: PropTypes.object,
  showCloseButton: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  titleClassName: PropTypes.string
}

export default PopUpWindow
