import React from 'react'
import { useNavigate } from 'react-router-dom'

import PropTypes from 'prop-types'

import { officialMessageTableHeaderCell } from '../../configs/messages'
import MessageTableRow from './MessageTableRow'

const MessageTableHeaderCell = ({ content, className }) => <th className={className}>{content}</th>

const MessagesTable = ({ messageType, tableData }) => {
  const navigate = useNavigate()

  const handleSelectedRow = id => navigate(`/admin/notificaciones/${id}`)

  return (
    <section className="overflow-x-auto">
      <table className="table table-auto w-full text-left mt-6">
        <thead>
          <tr>
            {officialMessageTableHeaderCell[messageType].map((content, index) => (
              <MessageTableHeaderCell key={index} content={content} className={index === 0 ? 'pl-6' : 'pl-0'} />
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData?.map((item, index) => {
            const { id, ...messageData } = item
            return <MessageTableRow key={index} rowData={messageData} onDataCellClick={() => handleSelectedRow(id)} />
          })}
        </tbody>
      </table>
    </section>
  )
}

MessagesTable.propTypes = {
  messageType: PropTypes.string,
  tableData: PropTypes.array
}

MessageTableHeaderCell.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string
}

export default MessagesTable
