import React from 'react'
import PropTypes from 'prop-types'

const MessageTableDataCell = ({ content, className, onDataCellClick }) => (
  <td className={className} onClick={onDataCellClick}>
    {content}
  </td>
)

const MessageTableRow = ({ rowData, onDataCellClick }) => {
  return (
    <tr className="bg-gray-30 border-b-8 border-white hover:bg-gray-80 h-16 cursor-pointer">
      {Object.entries(rowData).map(([key, value], index) => (
        <MessageTableDataCell
          key={index}
          content={value}
          className={index === 0 ? 'pl-6' : 'pl-0'}
          onDataCellClick={key === 'constancy' ? null : onDataCellClick}
        />
      ))}
    </tr>
  )
}

MessageTableRow.propTypes = {
  rowData: PropTypes.object,
  onDataCellClick: PropTypes.func
}

MessageTableDataCell.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  onDataCellClick: PropTypes.func
}

export default MessageTableRow
