import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

import { FORM_ERROR } from 'final-form'
import jwtDecode from 'jwt-decode'

import { request } from '../api/apiCore'
import CellPhoneIcon from '../assets/images/cellphone_icon.svg'
import { useAuth } from '../auth/Auth'
import { Container } from '../components/Container'
import CodeVerifyForm from '../components/forms/CodeVerifyForm'
import FormsHeader from '../components/ui/FormsHeader'
import { UserFormSteps } from '../configs/users'
import { getJsonItemFromLocalStorage } from '../helpers/helpers'

export const CodeVerifyPage = () => {
  const [verifySms, setVerifySms] = useState(false)
  const [valError, setValError] = useState(true)
  const [timerConfig, setTimerConfig] = useState({ show: true, reset: false })
  const { user, dispatch } = useAuth()
  const navigate = useNavigate()
  const errorObject = { [FORM_ERROR]: 'Código ingresado es incorrecto o ya caducó' }

  useEffect(() => window.scrollTo(0, 0))

  useEffect(() => {
    if (user.recoverySms || (user.verified && user.type === 'phone_number')) setVerifySms(true)
  }, [user])

  const handleSubmit = async ({ code }) => {
    try {
      if (user.verified) {
        const data = { code, email: user.email, salt: user.salt }
        const { token: jwt } = await request({ url: '/authentication/verify-code', data, method: 'POST' })

        if (!jwt) return errorObject

        const { user_id: id, name, father_last_name } = jwtDecode(jwt)
        dispatch({
          type: 'Login',
          payload: { id, name, father_last_name, jwt, document_number: user.document_number, type: 'cue' }
        })
      }

      if (user.registry) {
        const data = { code, salt: user.salt }
        const verifyCode = await request({ url: '/registration/verify-code', data, method: 'POST' })

        if (verifyCode.response?.data?.error) {
          setValError(true)
          return errorObject
        }

        if (!verifySms) {
          const verifyPhoneNumber = await request({
            url: '/registration/verify-phone-number.json',
            method: 'POST',
            data: getJsonItemFromLocalStorage('userRegistryData')
          })

          if (verifyPhoneNumber.response?.data.error)
            return { [FORM_ERROR]: `Ocurrió un error: ${verifyPhoneNumber.response.data.error}` }

          dispatch({ type: 'Registration', payload: { ...verifyPhoneNumber, jwt: user.jwt } })
          setVerifySms(true)
          setTimerConfig({ ...timerConfig, reset: true })
          window.scrollTo(0, 0)
        } else {
          dispatch({ type: 'Registration', payload: { ...verifyCode } })
          navigate('/contrasena-usuario')
        }
      }

      if (user.changePassword) {
        const data = verifySms
          ? { code, phone_number: user.phone_number, salt: user.salt }
          : { code, email: user.email, salt: user.salt }

        const { token: jwt } = await request({ url: '/authentication/verify-code', data, method: 'POST' })
        if (!jwt) {
          setValError(true)
          return errorObject
        }

        dispatch({ type: 'RecoverPasswordEmail', payload: { token: jwt } })
        navigate('/contrasena-usuario')
      }
    } catch (error) {
      return { [FORM_ERROR]: `Ocurrió un error ${error}` }
    }
  }

  const handleResend = async () => {
    setTimerConfig({ ...timerConfig, show: true })

    try {
      if (user.verified) {
        const data = { document_type_code: 'dni', document_number: user.document_number }
        const { salt } = await request({ url: '/authentication/send-code.json', data, method: 'POST' })

        if (!salt) {
          setTimerConfig({ ...timerConfig, show: false })
          return { [FORM_ERROR]: 'No se pudo reenviar codigo' }
        }
        dispatch({ type: 'Verification', payload: { ...user, salt } })
      }

      if (user.registry) {
        const data = verifySms ? { send_type: 'sms' } : { send_type: 'mail' }

        const { salt } = await request({ url: '/registration/resend-code.json', data, method: 'POST' })
        if (!salt) {
          setTimerConfig({ ...timerConfig, show: false })
          return { [FORM_ERROR]: 'No se pudo reenviar codigo' }
        }

        dispatch({ type: 'Registration', payload: { ...user, salt } })
      }

      if (user.changePassword) {
        const data = {
          send_type: verifySms ? 'sms' : 'mail',
          document_type_code: 'dni',
          document_number: user.document_number
        }

        const { salt } = await request({ url: '/authentication/send-code.json', data, method: 'POST' })

        if (!salt) {
          setTimerConfig({ ...timerConfig, show: false })
          return { [FORM_ERROR]: 'No se pudo reenviar codigo' }
        }
        dispatch({ type: 'RecoverPasswordEmail', payload: { ...user, salt } })
      }
    } catch (error) {
      setTimerConfig({ ...timerConfig, show: false })
      return { [FORM_ERROR]: `Ocurrió un error ${error}` }
    }
  }

  const handleButtonReturnChange = valSms => {
    setVerifySms(valSms)
    setTimerConfig({ ...timerConfig, show: false })
    setValError(false)
  }

  const handleButton = () => {
    setValError(false)
    if (user.registry && !verifySms) navigate(-1)

    if (verifySms) {
      setTimerConfig({ ...timerConfig, show: false })
      setVerifySms(false)
    }

    if (user.verified) {
      dispatch({ type: 'Logout' })
      navigate('/', { replace: true })
    }
    if (user.changePassword) navigate('/')
  }

  const getTexts = () => {
    const textData = {
      titleText: '',
      bottomButtonText: ''
    }

    if (user.verified) {
      textData.titleText = `¡Hola, ${user.name}!`
      textData.bottomButtonText = 'Ingresa con tu contraseña'
    }

    if (user.registry) {
      textData.titleText = !verifySms ? 'Validación de correo electrónico' : 'Validación de número de celular'
      textData.bottomButtonText = 'Regresar'
    }

    if (user.changePassword) {
      textData.titleText = 'Recuperar contraseña'
    }

    return textData
  }
  return (
    <Container className="px-5 pt-8 2xl:h-screen pb-14 md:px-20 md:pt-14 md:pb-18">
      {user.registry && (
        <FormsHeader
          title="Creando mi cuenta de carpeta ciudadana"
          steps={UserFormSteps}
          activeStep={!verifySms ? UserFormSteps.SET_CODE : UserFormSteps.SET_CODE_SMS}
        />
      )}
      <CodeVerifyForm
        title={getTexts().titleText}
        subtitle={`Hemos enviado un código al siguiente ${
          !verifySms ? 'correo electrónico' : 'número de celular'
        }, será valido por 10 minutos`}
        email={!verifySms ? user.email : user.phone_number}
        showEmail={(user.verified || user.changePassword) && false}
        showHomeLink={user.changePassword}
        showTextBetweenLines={(user.registry || user.changePassword) && false}
        textBetweenLines={getTexts().textBetweenLines}
        bottomButtonText={getTexts().bottomButtonText}
        bottomButtonVariant={(user.verified && 'secondary') || (user.registry && 'text')}
        bottomButtonIcon={user.verified && <ReactSVG src={CellPhoneIcon} className="hidden pr-3 md:block" />}
        handleSubmitForm={handleSubmit}
        handleCodeResend={handleResend}
        handleBottomButton={handleButton}
        passwordOptions={user.changePassword}
        showRecoveryPhone={verifySms}
        handleVerifySms={handleButtonReturnChange}
        showCodeError={valError}
        timerConfig={timerConfig}
        setTimerConfig={setTimerConfig}
      />
    </Container>
  )
}
