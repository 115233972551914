import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import '../assets/stylesheets/application.scss'
import Auth from '../auth/Auth'
import AppRouter from '../routers/AppRouter'

const App = () => {
  const siteKey = import.meta.env.VITE_CAPTCHA_SITE_KEY
  return (
    <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
      <Auth>
        <AppRouter />
      </Auth>
    </GoogleReCaptchaProvider>
  )
}

export default App
